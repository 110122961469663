/**
 * Script for All Pages
 *
 * @author Space-Time Inc.
 * @version 2022-10-18
 */

/**
 * Minimum Viewport (JS)
 *
 * @author Space-Time Inc.
 * @version 2022-10-18
 */

(() => {
	const MIN_WIDTH = 360;

	const vp = document.querySelector('meta[name="viewport"]');
	function fn() {
		const v = window.outerWidth > MIN_WIDTH ? 'width=device-width,initial-scale=1' : `width=${MIN_WIDTH}`;
		if (vp.getAttribute('content') !== v) {
			vp.setAttribute('content', v);
		}
	}
	addEventListener('resize', throttle(fn), false);
	fn();

	/**
	 * Throttling Function
	 *
	 * @author Space-Time Inc.
	 * @version 2022-10-13
	 */
	
	function throttle(fn) {
		let isRunning;
		return (...args) => {
			if (isRunning) return;
			isRunning = true;
			requestAnimationFrame(() => {
				isRunning = false;
				fn(...args);
			});
		};
	}
	
})();

/**
 * Hamburger (JS)
 *
 * @author Space-Time Inc.
 * @version 2023-07-04
 */

document.addEventListener('DOMContentLoaded', () => {

	const ham     = document.querySelector('.part-hamburger');
	const btnClose = document.querySelector('#close-hamburger');
	const overlay = document.getElementById('overlay');

		if (ham && btnClose) {
		btnClose.addEventListener('click', () => {
			ham.classList.remove('open');
			document.body.style.overflow = '';
			overlay.style.display = 'none';
		});
	}
		
	const btnOpens= document.querySelectorAll('.open-hamburger');
	for (let i = 0; i < btnOpens.length; i += 1) {
		if(btnOpens[i]) {
			btnOpens[i].addEventListener('click', () => {
				ham.classList.add('open');
				document.body.classList.add('hidden');
				overlay.style.display = 'block';
				overlay.style.zIndex = '3';
			});
		}
	}
	var scrollheaderHight = 200;
	var scrollThreshold = 500;

	window.addEventListener('scroll', function() {

		var scrollY = window.pageYOffset || document.documentElement.scrollTop;
		if (scrollY > scrollheaderHight ) {
			for (let i = 0; i < btnOpens.length; i += 1) {
				if(btnOpens[i] && !btnOpens[i].classList.contains('ready')) {
					btnOpens[i].classList.add('ready');
				}
			}
		} else { 
			for (let i = 0; i < btnOpens.length; i += 1) {
				if(btnOpens[i]) {
					btnOpens[i].classList.remove('ready');
				}
			}
		}
		// スクロール位置が閾値を超えたらfixedに変更
		if (scrollY > scrollThreshold) {
			for (let i = 0; i < btnOpens.length; i += 1) {
				if((btnOpens[i] && !btnOpens[i].classList.contains('fixed'))) {
					btnOpens[i].classList.add('fixed');
				}
			}
		} else {
			for (let i = 0; i < btnOpens.length; i += 1) {
				if(btnOpens[i] && btnOpens[i].classList.contains('fixed')) {
					btnOpens[i].classList.remove('fixed');
				}
			}
		}
	});
});

/**
 * Back To Top
 *
 * @author Space-Time
 * @version 2022-10-18
 */

(() => {
	function init() {
		const OFFSET_Y = 0;

		const btn = document.getElementById('back-to-top');
		const ph = btn.parentElement;

		onScroll(scrollHandler);
		onResize(scrollHandler);
		if ('ontouchstart' in window) window.addEventListener('touchmove', scrollHandler);
		setTimeout(scrollHandler, 100);

		function scrollHandler() {
			const viewBottomY = window.innerHeight + OFFSET_Y;
			const footerTopY = ph.getBoundingClientRect().top;

			if (viewBottomY > footerTopY) {
				btn.style.transform = 'translateY(-' + (viewBottomY - footerTopY) + 'px)';
				btn.classList.remove('fixed');
			} else {
				btn.style.transform = '';
				btn.classList.add('fixed');
			}

			if (window.pageYOffset > window.innerHeight / 3) {
				btn.classList.remove('hidden');
			} else {
				btn.classList.add('hidden');
			}
		}
	}

	// -------------------------------------------------------------------------

	const resizeListeners = [];
	const scrollListeners = [];

	function onResize(fn, doFirst = false) {
		if (doFirst) fn();
		resizeListeners.push(throttle(fn));
	}

	function onScroll(fn, doFirst = false) {
		if (doFirst) fn();
		scrollListeners.push(throttle(fn));
	}

	function throttle(func, limit = 100) {
		let lastFunc;
		let lastRan;
		return function() {
			const context = this;
			const args = arguments;
			if (!lastRan) {
				func.apply(context, args);
				lastRan = Date.now();
			} else {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(function() {
					if ((Date.now() - lastRan) >= limit) {
						func.apply(context, args);
						lastRan = Date.now();
					}
				}, limit - (Date.now() - lastRan));
			}
		};
	}

	document.addEventListener('DOMContentLoaded', () => {
		window.addEventListener('resize', () => {
			for (const l of resizeListeners) l();
		}, { passive: true });
		window.addEventListener('scroll', () => {
			for (const l of scrollListeners) l();
		}, { passive: true });
		init();
	});
})();
/**
 * Toggle Button
 *
 * @author Space-Time Inc.
 * @version 2022-07-11
 */

document.addEventListener('DOMContentLoaded', () => {

	const btns = document.querySelectorAll('.user-content *[class^="button"]');

	for (let i = 0; i < btns.length; i += 1) {
		if (isToggleButton(btns[i])) setEventListener(btns[i]);
	}

	function isToggleButton(btn) {
		if (!btn.href || btn.href[btn.href.length - 1] !== '#') return false;
		const p = btn.parentElement;
		const f = p.nextElementSibling;
		return f.classList.contains('frame') || f.classList.contains('frame-alt');
	}

	function setEventListener(btn) {
		btn.classList.add('toggle');
		initFrame(btn);
		btn.addEventListener('click', (e) => {
			if (btn.classList.contains('active')) {
				btn.classList.remove('active');
				setFrameVisible(btn, false);
			} else {
				btn.classList.add('active');
				setFrameVisible(btn, true);
			}
			e.preventDefault();
		});
	}

	function initFrame(btn) {
		const p = btn.parentElement;
		const f = p.nextElementSibling;
		f.style.display = 'none';
		f.classList.add('hidden');
		setTimeout(() => { f.style.display = ''; }, 10);
	}

	function setFrameVisible(btn, visible) {
		const p = btn.parentElement;
		const f = p.nextElementSibling;
		if (visible) {
			f.classList.remove('hidden');
		} else {
			f.classList.add('hidden');
		}
	}

});

